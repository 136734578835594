export const CEO = `
---CEO---
Deze reiziger is een CEO!

Voorbereiding:
- Flesje water (Fruitsap/koffiekoek bij ochtend rit) voorzien!
- Wagen op temperatuur 20 graden houden
- Passagier voorste stoel beetje naar voren voor beenruimte
- Carwash proper en volgetankte wagen

Parkeren en instappen:
- Best parkeren met passagiers zijde richting het instappen
- Deur openen achterbank passagiers zijde voor het instappen
- Jas en tas aannemen
- Deur sluiten
- Andere kant deur openen
- Jas en tas naast hem op de bank leggen

Tijdens het rijden:
- Rijd VEILIG en ZONDER SCHOKKEN (!!)
`.trim()

export const BELANGRIJK = `
---BELANGERIJKE KLANT---

Voorbereiding:
- Wagen op temperatuur 20 graden houden
- Carwash proper en volgetankte wagen

Parkeren en instappen:
- Best parkeren met passagiers zijde richting het instappen
- Deur openen achterbank passagiers zijde voor het instappen
- Deur sluiten

Tijdens het rijden:
- Rijd VEILIG en ZONDER SCHOKKEN
`

export default {
  CEO,
  REISBUREAU: BELANGRIJK,
}
